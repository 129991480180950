export default {
  state: {
    surveys: [],
  },
  getters: {
    Surveys: s => s.surveys, 
  },
  mutations: {
    updateSurveys(s, surveys) {
      s.surveys = surveys
    },
  },
  actions: {
    async fetchSurveys(ctx) {
      return await this.$axios({
        method: 'GET',
        url: `/api/surveys/`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateSurveys', result)
          return true
        })
        .catch((error) => {
          
          return false
        });
    },
  },
}

import map from './modules/map'
import auth from './modules/auth'
import user from './modules/user'
import rules from './modules/rules'
import alert from './modules/alert'
import search from './modules/search'
import filter from './modules/filter'
import filters from './modules/filterItems'
import surveys from './modules/surveys'
import medInfo from './modules/medInfo'
import restore from './modules/restore'
import content from './modules/content'
import register from './modules/register'
import facecast from './modules/facecast'
import education from './modules/education'
import materialsFilter from './modules/materialsFilter'

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function clearOldRequest(state) {
  state.requests.push(state.activeReq);
  state.activeReq = null;
}

export default new Vuex.Store({
  state: {
    requests: [],
    activeReq: null
  },
  mutations: {
    addRequest: (state, req) => {
      state.activeReq = { controller: req.controller, url: req.url.split("?")[0] };
    },

    cancelReq(state) {
      // state.activeReq.cancel();
      state.activeReq.controller.abort()
      clearOldRequest(state);
    },

    requestSucceed(state) {
      clearOldRequest(state);
    },

    requestFailed(state) {
      clearOldRequest(state);
    },

    clearOldRequest,

    reset(state) {
      state.requests = [];
    }
  },
  actions: {
    addRequest({ state, commit }, req) {
      if (state.activeReq && (state.activeReq.url === req.url.split("?")[0])) commit("cancelReq");
      commit("addRequest", req);
    }
  },
  modules: {
    map,
    auth,
    user,
    rules,
    alert,
    search,
    filter,
    filters,
    surveys,
    medInfo,
    restore,
    content,
    register,
    facecast,
    education,
    materialsFilter
  }
})

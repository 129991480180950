import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'

axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_HOST}/`;
axios.defaults.withCredentials = true;
axios.defaults.headers.common.Authorization = localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null;
const ssoLinks = {
  registration: `${process.env.VUE_APP_SSO_HOST}signup?source=${encodeURIComponent(window.location.href)}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.href)}`,
  login: `${process.env.VUE_APP_SSO_HOST}signin?source=${encodeURIComponent(window.location.href)}&next_url=${window.location.origin}/az-sso&back_url=${encodeURIComponent(window.location.href)}`
}



export default {
  async install() {
    Vue.prototype.$axios = axios
    Vuex.Store.prototype.$axios = axios

    function request(request) {
      if(request.params) {
        request.systemParams = new URLSearchParams(request.params.toString())
      }
      
      if (request.headers.common.Authorization) {
        if (request.params) {
          request.params.delete('access')
        }
      }


      const controller = new AbortController();
      store.dispatch("addRequest", {
        ...request,
        controller: controller
      });
      return {
        ...request,
        signal: controller.signal,
      };
    }

    function requestError() {
      store.commit("requestFailed");
    }
    await axios.interceptors.request.use(request, requestError);
    await axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {

        const originalRequest = error.config;
        if (error.code != "ERR_CANCELED") {
          console.error(error)
        }
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          !error.config.url.includes("refresh")
        ) {
          localStorage.removeItem("token");
          delete axios.defaults.headers.common.Authorization;
          if (localStorage.getItem("refresh")) {
            originalRequest._retry = true;
            let fd = new FormData();
            fd.append("refresh", localStorage.getItem("refresh"));
            return await fetch(
              `${process.env.VUE_APP_BACKEND_HOST}/api/auth/sso-update-tokens`,
              {
                method: "POST",
                body: fd,
              }
            ).then(async (res) => {
              if (res.status === 200) {
                let result = await res.json();
                // 1) put token to LocalStorage
                localStorage.setItem("token", result.access_token);
                localStorage.setItem("refresh", result.refresh);
                // 2) Change Authorization header
                axios.defaults.headers.common.Authorization =
                  "Bearer " + result.access_token;
                originalRequest.headers.Authorization =
                  "Bearer " + result.access_token;
                // 3) return originalRequest object with Axios.
                await store.dispatch('getSession')
                return Promise.resolve(
                  await axios.request(originalRequest)
                );

              } else {
                delete axios.defaults.headers.common.Authorization;
                delete originalRequest.headers.Authorization;
                localStorage.removeItem("token");
                localStorage.removeItem("refresh");


                if (window.location.search.includes('access')) {
                  if (originalRequest.params && originalRequest.params.get('access')) {
                    window.open(ssoLinks.login, "_self");
                    // return Promise.reject(error);
                  } else {
                    if (originalRequest.systemParams && originalRequest.systemParams.get('access')) {
                      originalRequest.params.append('access', originalRequest.systemParams.get('access'))
                    }

                    return Promise.resolve(
                      await axios.request(originalRequest)
                    );
                  }


                }

                return Promise.reject(error);
              }
            });
          } else {
            localStorage.removeItem("token");
            delete axios.defaults.headers.common.Authorization;
            delete originalRequest.headers.Authorization;
            originalRequest._retry = true;
            if (window.location.search.includes('access')) {
              if (originalRequest.params && originalRequest.params.get('access')) {
                window.open(ssoLinks.login, "_self");
                // return Promise.reject(error);
              } else {
                return Promise.resolve(
                  await axios.request(originalRequest)
                );
              }


            }

            return Promise.reject(error);
          }
        } else if (
          error &&
          error.response &&
          error.response.status === 401 &&
          originalRequest._retry
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");

        }

        return Promise.reject(error);
      }
    );
  }
};
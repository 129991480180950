export default ({
  state: {
    nosologysFilter: [],
    typesFilter: [],
    textTypes: [],
    videoTypes: [],
    archiveEventTypes: [],
    podcastsTypes: [],
    disease: [],
    epicentralFormat: []
  },
  getters: {
    NosologysFilter: s => s.nosologysFilter,
    TypesFilter: s => s.typesFilter,
    TextTypesFilter: s => s.textTypes,
    VideoTypesFilter: s => s.videoTypes,
    PodcastsTypesFilter: s => s.podcastsTypes,
    DiseaseFilter: s => s.disease,
    EpicentralFormatsFilter: s => s.epicentralFormat,
    ArchiveEventTypesFilter: s => s.archiveEventTypes
  },
  mutations: {
    updateNosologysFilter(s, nosologys) {
      s.nosologysFilter = nosologys.sort(function (a, b) {
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      })
    },
    updateTypesFilter(s, types) {
      s.typesFilter = types.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })
    },
    updateTextTypesFilter(s, types) {
      s.textTypes = types.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })
    },
    updateVideoTypesFilter(s, types) {
      s.videoTypes = types.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })
    },
    updatePodcastsFilter(s, types) {
      s.podcastsTypes = types.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })
    },
    updateDiseaseFilter(s, diseases) {
      s.disease = diseases.sort(function (a, b) {
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      })
    },
    updateEpicentralFormatsFilter(s, formats) {
      s.epicentralFormat = formats.sort(function (a, b) {
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      })
    },
    updateArchiveEventTypesFilter(s, types) {
      s.archiveEventTypes = types.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })
    }
  },
  actions: {
    async fetchNosologysFilter(ctx) {
      return await this.$axios({
        method: 'GET',
        headers: {
          common: {
            Authorization: null,
          }
        },
        url: '/api/event/nosology/',
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateNosologysFilter', result)
          return true

        })
        .catch((error) => {

          return false
        });
    },
    async fetchTypesFilter(ctx) {
      return await this.$axios({
        method: 'GET',
        headers: {
          common: {
            Authorization: null,
          }
        },
        url: '/api/event/type/',
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateTypesFilter', result)
          return true

        })
        .catch((error) => {

          return false
        });
    },
    async fetchLibraryFilter(ctx) {
      return await this.$axios({
        method: 'GET',
        headers: {
          common: {
            Authorization: null,
          }
        },
        url: '/api/library/filters',
      })
        .then(async (response) => {
          const result = response.data;
          if (result)
            ctx.commit('updateNosologysFilter', result.nosologies) //.filter(el => el.active)
          ctx.commit('updateTextTypesFilter', result.text_types)
          ctx.commit('updateVideoTypesFilter', result.video_types)
          ctx.commit('updatePodcastsFilter', result.podcast_types)
          ctx.commit('updateDiseaseFilter', result.diseases) //.filter(el => el.active)
          ctx.commit('updateArchiveEventTypesFilter', result.archive_events_type)
          return true

        })
        .catch((error) => {

          return false
        });
    },
    async fetchEpicentralFormatsFilter(ctx) {
      // return await this.$axios({
      //   method: 'GET',
      //   headers: {
      //      common: {
      // Authorization: null,
      // }
      //   },
      //   url: '/api/library/filters',
      // })
      //   .then(async (response) => {
      //     const result = response.data;
      //     if (result)
      //       ctx.commit('updateNosologysFilter', result.nosologies) //.filter(el => el.active)
      //       ctx.commit('updateTextTypesFilter', result.text_types)
      //       ctx.commit('updateVideoTypesFilter', result.video_types)
      //       ctx.commit('updatePodcastsFilter', result.podcast_types)
      //       ctx.commit('updateDiseaseFilter', result.diseases) //.filter(el => el.active)
      //     return true

      //   })
      //   .catch((error) => {
      //     
      //     return false
      //   });
      ctx.commit('updateEpicentralFormatsFilter', [{ title: "Видео", slug: "video" }, { title: "Статьи", slug: "text" }])
    },
  },
})